import styled from 'styled-components';
import { CSSVars } from '../../styles/styles.config';

export const StyledDiv = styled.div`
	p {
		text-align: center;
		font-weight: 600;
		font-size: ${CSSVars.fontSize2XL};
		color: ${CSSVars.gray900};
		margin-bottom: 50px;

		@media screen and (min-width: ${CSSVars.breakpointMD}) {
			font-size: ${CSSVars.fontSize4XL};
		}
	}

	.companies-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		max-width: 1200px;
		flex-wrap: wrap;
		margin: 0 auto;
		gap: 20px;

		img {
			transform: scale(0.9);
			@media screen and (min-width: ${CSSVars.breakpointMD}) {
				transform: scale(1);
			}
		}

		@media screen and (min-width: ${CSSVars.breakpointMD}) {
			gap: 50px;
			font-size: ${CSSVars.fontSize4XL};
		}
	}
`;
