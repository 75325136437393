import React, { ReactNode } from 'react';
import { CSSProperties } from 'styled-components';
import { StyledSection } from './section.styles';


export const Section = ({ children, style = {}, mode = 'light' }: { children: ReactNode, style?: CSSProperties, mode?: 'dark' | 'light'; }) => {
    return (
        <StyledSection style={style} className={mode}>
            {children}
        </StyledSection>
    );
};

