import styled from 'styled-components';
import { CSSVars } from '../../styles/styles.config';

export const StyledDiv = styled.div`
	h2 {
		text-align: center;
		font-weight: 600;
		font-size: ${CSSVars.fontSize2XL};
		color: ${CSSVars.gray900};
		max-width: 920px;
		margin: 0 auto 50px;

		@media screen and (min-width: ${CSSVars.breakpointMD}) {
			font-size: ${CSSVars.fontSize4XL};
		}
	}

	.show-more {
		margin: 50px auto;
		color: ${CSSVars.primary500};
		background: none;
		font-size: ${CSSVars.fontSizeSM};

		.icon {
			padding-top: 2px;
		}

		@media screen and (min-width: ${CSSVars.breakpointMD}) {
			font-size: ${CSSVars.fontSizeMD};
		}
		&:hover {
			background: none;
			color: ${CSSVars.primary300};
		}
	}

	.companies-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		max-width: 1200px;
		flex-wrap: wrap;
		margin: 0 auto;
		gap: 15px;

		img {
			transform: scale(0.9);
			@media screen and (min-width: ${CSSVars.breakpointMD}) {
				transform: scale(1);
			}
		}

		@media screen and (min-width: ${CSSVars.breakpointMD}) {
			gap: 40px;
			font-size: ${CSSVars.fontSize4XL};
		}
	}
`;
