import styled from 'styled-components';
import { CSSVars } from '../../styles/styles.config';

export const StyledDiv = styled.div`
	/* max-width: 1420px; */
	margin: 0 auto;

	display: flex;
	flex-wrap: wrap;
	gap: 50px;

	flex-direction: column;

	.feature-wrapper {
		max-width: 1420px;
		margin: 0 auto;
		padding: 50px 20px;

		@media screen and (min-width: ${CSSVars.breakpointMD}) {
			padding: 100px 40px;
		}
	}

	.card {
		&:nth-child(even) {
			flex-direction: row-reverse;
		}
	}

	.benefits {
		background-color: ${CSSVars.white};
		padding: 50px 20px;

		@media screen and (min-width: ${CSSVars.breakpointMD}) {
			padding: 100px 40px;
		}
		
		.inner {
			display: flex;
			flex-wrap: wrap;
			gap: 30px;
			max-width: 1420px;
			margin: 0 auto;

			@media screen and (min-width: ${CSSVars.breakpointMD}) {
				gap: 50px;
			}
		}

		.card {
			flex: 300px;

			img {
				width: 25px;
				height: 25px;
				object-fit: contain;

				@media screen and (min-width: ${CSSVars.breakpointMD}) {
					width: 30px;
					height: 30px;
				}
			}

			h3 {
				font-weight: 600;
				font-size: ${CSSVars.fontSizeLG};
				margin: 20px 0 10px;

				@media screen and (min-width: ${CSSVars.breakpointMD}) {
					margin: 30px 0 10px;
					font-size: ${CSSVars.fontSizeXL};
				}
			}

			p {
				font-size: ${CSSVars.fontSizeMD};
				font-weight: 300;

				@media screen and (min-width: ${CSSVars.breakpointMD}) {
					font-size: ${CSSVars.fontSizeLG};
				}
			}
		}
	}
`;
