import React from "react";
import { IPage, IPlugin } from "../../types";
import { Section } from "../section/section.comp";
import { StyledDiv } from "./widgetFeatures.styles";
import { Feature } from "../feature/feature.comp";
import Image from "next/image";
import { CSSVars } from "@/styles/styles.config";

export const WidgetFeatures = ({
  widget,
  page,
}: {
  widget: IPlugin;
  page?: IPage;
}) => {
  // Features
  const sectionFeaturesApp = widget.features.filter(
    (feature) => feature.type === "section" || !feature.type
  );
  const sectionFeaturesPage = page?.rewrites.features
    .map((f, idx) => ({
      ...f,
      type: widget.features[idx]?.type,
      imgUrl: widget.features[idx]?.imgUrl || f.imgUrl,
    }))
    .filter((feature) => feature.type === "section" || !feature.type);
  const finalFeaturesSections =
    (!page ? sectionFeaturesApp : sectionFeaturesPage) || [];
  const middle = Math.ceil(finalFeaturesSections.length / 2);
  const first = finalFeaturesSections.slice(0, middle);
  const second = finalFeaturesSections.slice(middle);

  // Boxes
  const boxFeaturesApp = widget.features.filter(
    (feature) => feature.type === "box"
  );
  const boxFeaturesPage = page?.rewrites.features
    .map((f, idx) => ({
      ...f,
      type: widget.features[idx]?.type,
      imgUrl: widget.features[idx]?.imgUrl || f.imgUrl, // Use images from original features
    }))
    .filter((feature) => feature.type === "box");
  const finalFeaturesBox = (!page ? boxFeaturesApp : boxFeaturesPage) || [];

  return (
    <Section
      style={{ backgroundColor: `${CSSVars.gray700}`, padding: '0' }}
    >
      <StyledDiv>
        <div className="feature-wrapper">
          {first.map((feature, idx) => (
            <Feature
              mode="dark"
              key={`${feature.title}-app`}
              title={feature.title}
              text={feature.text}
              position={idx % 2 === 0 ? "ltr" : "rtl"}
              alt={`${widget?.name} - ${feature.title}`}
              img={feature.imgUrl}
            />
          ))}
        </div>
        {finalFeaturesBox.length > 0 && (
          <div className="benefits">
            <div className="inner">
              {finalFeaturesBox.map((feature, idx) => {
                let text = feature.text;
                if (idx === 0) {
                  text = feature.text.slice(0, 175);
                }
                if (idx === 1) {
                  text = feature.text.slice(0, 228);
                }
                if (idx === 2) {
                  text = feature.text.slice(0, 150);
                }
                if (idx === 3) {
                  text = feature.text.slice(0, 210);
                }

                return (
                  <div key={feature.title} className="card">
                    <Image
                      src={feature.imgUrl}
                      alt={`${widget?.name} - ${feature.title}`}
                      width={30}
                      height={30}
                    />
                    <h3>{feature.title}</h3>
                    <p>{text}</p>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        <div className="feature-wrapper">
          {second.map((feature, idx) => (
            <Feature
              mode="dark"
              key={`${feature.title}-app`}
              title={feature.title}
              text={feature.text}
              position={idx % 2 === 0 ? "ltr" : "rtl"}
              alt={`${widget?.name} - ${feature.title}`}
              img={feature.imgUrl}
            />
          ))}
        </div>
      </StyledDiv>
    </Section>
  );
};
