import styled from 'styled-components';
import { CSSVars } from '../../styles/styles.config';

export const StyledSection = styled.section`
	padding: 50px 20px;

	@media screen and (min-width: ${CSSVars.breakpointLG}) {
		padding: 100px 40px;
	}

	&.dark {
		background-color: ${CSSVars.gray700};
	}
`;
